<template>
  <v-app>
    <div class="backgruond"></div>
    <v-main class="d-flex justify-center align-center">
      <v-col cols="10" lg="4" class="mx-auto">
        <v-card elevation="0" class="pa-4">
          <div class="text-center mb-md-1 mb-5">
            <div class="d-flex justify-content-center align-center">
              <img
                height="60px"
                src="@/assets/logo.jpg"
                max-height="110px"
                max-width="110px"
                alt="admin"
                class="mx-auto"
              />
            </div>
            <h3 class="mb-0 mt-1 irgibd--text">School Management </h3>
          </div>
          <v-form @submit.prevent="submitHandler" ref="form">
            <v-card-text>
              <v-row align="center">
                <v-col class="d-flex" cols="12" sm="12">
                  <v-select
                    v-model="userType"
                    :rules="Typerule"
                    :items="userTypes"
                    label="Type d'utilisateur"
                    prepend-inner-icon="mdi-account"
                    class="clickable"
                    disabled
                  ></v-select>
                </v-col>
                <v-col class="d-flex" cols="12" sm="12">
                  <v-text-field
                    color="#144881"
                    v-model="username"
                    :rules="identifiatRule"
                    type="text"
                    label="Identifiant"
                    placeholder="Identifiant"
                    prepend-inner-icon="mdi-account"
                    class="clickable"
                  />
                </v-col>
              </v-row>
              <v-text-field
                color="#144881"
                v-model="password"
                :rules="passwordRules"
                :type="passwordShow ? 'text' : 'password'"
                label="Password"
                placeholder="Password"
                prepend-inner-icon="mdi-key"
                :append-icon="passwordShow ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="passwordShow = !passwordShow"
              />
            </v-card-text>
            <v-card-actions class="justify-center">
              <v-btn
                :loading="loading ? '#144881' : null"
                block
                type="submit"
                color="#144881"
                class=""
                rounded
              >
                <span class="white--text px-12">Se connecter</span>
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-col>
    </v-main>
    <v-snackbar top color="green" v-model="snackbar">
      Login success
    </v-snackbar>
  </v-app>
</template>

<script>
import { login } from "@/api/auth";
export default {
  name: "SignIn",

  data: () => ({
    info: [],

    listData: [],
    userTypes: ["Utilisateur", "Admin"],
    icons: ["mdi-facebook", "mdi-twitter", "mdi-linkedin", "mdi-instagram"],
    loading: false,
    snackbar: false,
    passwordShow: false,
    username: "",
    identifiatRule: [(v) => !!v || "Identifiant Requise"],
    password: "",
    passwordRules: [
      (v) => !!v || "Password is ",
      (v) => (v && v.length >= 6) || "Password must be 6  characters or more!",
    ],
    userType: "Utilisateur",
    Type: "",
    Typerule: [(v) => !!v || "Choix du type d'utilsateur requise"],
  }),

  methods: {
    async submitHandler() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        // this.snackbar = true;

        try {
          await login({
            username: this.username,
            password: this.password,
            userType: this.userType,
          });
          location.reload();
        } catch (error) {
          // alert("er");
        }
        this.loading = false;
      }
    },
  },
};
</script>
<style>
.backgruond {
  height: 100%;
  width: 100%;
  display: block;
  position: absolute;
  top: 0;
  /* background-image: url(../assets/bck.jpg); */
  background-size: cover;
  background-color: #144881;
}
</style>
